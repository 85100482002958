import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';
import { generateOnlineStore } from '@/utils/schema';
import { decodeHTMLEntities } from '@/utils/common';
// Json format to set seo meta data
// const hocHeadData = {
//   meta: {
//     title: null,
//     robots: null,
//     keyword: null,
//     description: null,
//     image: null,
//   },
//   jsonLdData: {
//     faqSchema: null,
//     listItemSchema: null,
//     breadcrumbSchema: null,
//   },
// };

interface Props {
  children?: React.ReactNode;
  seo?: any;
}

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

const HocHead: React.FC<Props> = ({ children, seo = {} }) => {
  const router = useRouter();
  const cleanUrl = router.asPath.split('?')[0];
  const canonicalURL = `${baseUrl}${cleanUrl}`;
  const jsonLdData = generateOnlineStore();
  const imageBaseS3Url = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const meta = seo.meta;

  const defaultMeta = {
    image: meta?.image ?? `${imageBaseS3Url}/images/cover.gif`,
    keyword:
      meta?.keyword?.trim() ??
      'furniture online, wooden furniture, furniture India, solid wood furniture, buy furniture in India, buy wooden furniture online, customized furniture in India, shop furniture in India, online furniture for home',
    description:
      meta?.description?.trim() ??
      'Furniture Online: Buy Wooden Furniture Online for Home & Office. Browse exclusive range of Living, Dining & Bedroom Furniture online at best prices made of solid wood. Buy from popular Wooden Furniture Store!',
    title:
      meta?.title?.trim() ??
      'Furniture Online: Buy Wooden Furniture Online for Home in India - Wooden Street',
    robots:
      meta?.robots && meta?.robots.trim() !== ''
        ? meta?.robots
        : 'INDEX, FOLLOW',
  };

  const rules: any = process.env.NEXT_PUBLIC_APP_MODE === 'production';

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="Language" content="English" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#435471" />
        <meta name="author" content={`${process.env.NEXT_PUBLIC_SITE_URL}`} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
        <meta httpEquiv="x-dns-prefetch-control" content="on" />

        <meta property="og:type" content="website" />
        <meta
          property="og:site"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}`}
        />
        <meta property="og:url" content={canonicalURL} />
        <meta
          property="og:title"
          content={decodeHTMLEntities(defaultMeta?.title)}
        />
        <meta
          property="og:description"
          content={decodeHTMLEntities(defaultMeta?.description)}
        />
        <meta property="og:image" content={defaultMeta?.image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content={decodeHTMLEntities(defaultMeta?.title)}
        />
        <meta
          property="twitter:description"
          content={decodeHTMLEntities(defaultMeta?.description)}
        />
        <meta name="twitter:image" content={defaultMeta?.image} />
        <link rel="canonical" href={decodeHTMLEntities(canonicalURL)} />

        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`/apple-touch-icon.png"`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicon-16x16.png`}
        />

        <title>{decodeHTMLEntities(defaultMeta?.title)}</title>
        <meta
          name="keywords"
          content={decodeHTMLEntities(defaultMeta?.keyword)}
        />
        <meta
          name="description"
          content={decodeHTMLEntities(defaultMeta?.description)}
        />

        <meta
          name="ROBOTS"
          content={rules ? defaultMeta?.robots : 'NOINDEX, NOFOLLOW'}
        />

        {children ? children : <></>}
      </Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: jsonLdData,
        }}
      />

      {/* {seo?.jsonLdData &&
        Object.keys(seo.jsonLdData).map((key) => {
          const schema = seo.jsonLdData[key];
          return (
            schema && (
              <script
                key={key}
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: schema,
                }}
              />
            )
          );
        })
      } */}
    </>
  );
};

export default HocHead;
